@font-face {
  font-family: 'Avenir Next Rounded Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next Rounded Medium'), url('../src/assets/fonts/AvenirNextRoundedStd-Med.otf') format('opentype');
}

@font-face {
  font-family: 'Avenir Next Rounded Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next Rounded Regular'), url('../src/assets/fonts/AvenirNextRoundedStd-Reg.otf') format('opentype');
}

@font-face {
  font-family: 'Avenir Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Medium'), url('../src/assets/fonts/Avenir-Next-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Avenir Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Regular'), url('../src/assets/fonts/AvenirNextLTPro-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Montserrat Extra Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Extra Bold'), url('../src/assets/fonts/Montserrat-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Bold'), url('../src/assets/fonts/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Regular'), url('../src/assets/fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Medium'), url('../src/assets/fonts/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Urbanist regular';
  font-style: normal;
  font-weight: normal;
  src: local('Urbanist Regular'), url('../src/assets/fonts/Urbanist-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Urbanist Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Urbanist Medium'), url('../src/assets/fonts/Urbanist-Medium.ttf') format('truetype');
}

